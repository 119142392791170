<template>
  <Home />
</template>

<script>
import Home from "@/views/retailer/Home/RetailerHome.vue";
export default {
  components: { Home },
};
</script>

<style lang="scss" scoped></style>
